@media all {
  .error-message {
    color: rgb(245, 69, 0);
  }

  .checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    width: 100%;
    padding-bottom: 18px;
  }
    .checkout .content {
      flex-direction: column;
      width: 100%;
      justify-content: space-around;
    }
      .checkout .order-summary .order-product .order-product-img {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 85px;
        height: 75px;
      }

  .delivery-address-form {
    position: relative;
  }
    .delivery-address-form .name-inputs {
      display: flex;
      flex-direction: column;
    }
      .delivery-address-form .name-inputs .first-name {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
      }

    .delivery-address-form .row-name-inputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    
    .delivery-address-form .edit {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
    .delivery-address-form .edit:hover {
      color: #FEA100;
    }

  .delivery-address {
    padding: 12px;
    border-radius: 0.5em;
    background-color: white;
  }
    .note {
      font-size: 12px;
      opacity: 0.5;
    }
    .delivery-address h2 {
      margin-bottom: 0px;
    }

  .delivery-address-form-submitted {
    background-color: rgb(241, 241, 241);
  }
  .delivery-address-form .button {
    margin-top: 12px;
    padding: 0px;
  }

  .payment-details {
    padding: 12px;
    border-radius: 0.5em;
    margin-top: 18px;
    background-color: white;
  }
    .payment-details .StripeElement {
      font-family: var(--secondary-font);
      border: 0.5px solid #9D9999;
      font-size: 16px;
      margin-bottom: 12px;
      padding: 16px;
      resize: none;
      border-radius: 1em;
    }
    .payment-details button {
      margin-top: 12px;
      padding: 0px;
      width: 100%;
    }
    .payment-details fieldset {
      border: none;
      padding: inherit;
    }

  .order-summary {
    padding: 12px;
    margin-top: 18px;
    height: fit-content;
    border-radius: 0.5em;
    background-color: white;
  }
    .order-summary .costs {
      font-size: 14px;
    }
      .order-summary .costs p {
        margin-bottom: 0px;
      }
    .order-summary h2 {
      margin-bottom: 4px;
    }
  .order-products {
    margin-top: 18px;
  }
    .order-product hr {
      width: 100%;
    }
    .order-product .order-product-details {
      width: 100%;
      padding-left: 8px;
    }
    .order-product .order-product-details p {
      margin: 0px;
      margin-bottom: 8px;
    }
}

@media (min-width: 1024px) {
    .checkout .content {
      flex-direction: row;
      min-height: 65vh;
    }

  .order-summary {
    min-width: 500px;
    width: 45%;
    margin-top: 0px;
    padding: 18px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .payment-details {
    padding: 18px;
  }
    .payment-details button {
      height: 60px;
    }

  .delivery-address {
    padding: 18px;
  }

  .checkout-forms {
    width: 45%;
    min-width: 500px;
  }
}