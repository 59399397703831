@media all {
  .product {
    position: relative;
    width: 100%;
  }
    .product .mob-back-arrow {
      position: absolute;
      left: 8px;
      top: 8px;
      color: #F56600;
    }

  .product-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FCFBED;
    padding: 8px;
  }
    .product-gallery .product-image {
      height: 150px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 70vw;
      height: 70vw;
    }
  
  .product-description h3 {
    font-size: 24px;
  }
    .product-description p {
      text-align: justify;
    }
  .ingredients h3 {
    font-size: 24px;
  }
    .ingredients p {
      text-align: justify;
    }

  .product-detail-selection {
    width: 100%;
  }
    .product-detail-selection h3 {
      margin-bottom: 8px;
    }
    .product-detail-selection h3 {
      font-size: 20px;
    }
    .product-detail-selection h3.secondary-font {
      font-size: 18px;
    }
    .product-detail-selection .row.space-between {
      align-items: baseline;
    }
    .product-detail-selection hr {
      width: 100% !important;
      margin: 0px;
    }
    .product-detail-selection .product-size {
      border: 1px solid #6F1D00;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3em;
      height: 3em;
      border-radius: 1.5em;
      color: #6F1D00;
      margin-right: 0.5em;
    }
    .product-detail-selection .product-size:hover {
      background-color: #6F1D00;
      cursor: pointer;
      color: white;
    }
    .product-detail-selection .selected {
      background-color: #6F1D00;
      cursor: pointer;
      color: white;
    }
    .product-detail-selection .quantity-input {
      width: 30px;
      height: 15px;
      padding: 10px;
    }
    .product-detail-selection button {
      font-size: 16px;
      margin-top: 28px;
    }
    .product-detail-selection .input-header {
      font-size: 14px;
    }
    .product-detail-selection .availability {
      font-size: 12px;
      align-items: baseline;
    }
    .product-detail-selection .availability h3, 
    .product-detail-selection .availability p {
      font-size: 12px;
      margin-top: 4px;
    }
      .product-detail-selection .availability .availability-icon {
        margin-top: 4px;
        margin-left: 4px;
        margin-right: 12px;
        height: 12px;
        width: 12px;
        border-radius: 6px;
      }
        .product-detail-selection .availability .yes {
          background-color: green;
        }
        .product-detail-selection .availability .no {
          background-color: #C03600;
        }
        .product-detail-selection .availability .in-stock {
          position: relative;
        }
          .product-detail-selection .availability .in-stock .in-stock-panel {
            position: absolute;
            z-index: 10;
            top: 20px;
            background-color: white;
            right: 10px;
            padding: 8px;
            width: 100px;
          }
            .product-detail-selection .availability .in-stock .in-stock-panel p {
              margin: 0px;
            }

  .ingredients h3 {
    margin-bottom: 0px;
  }

  .other-products-container {
    justify-content: space-between;
    flex-wrap: wrap;
  }
    .other-product-container {
      display: flex;
      flex-direction: column;
      width: 125px;
    }
      .other-product-container h3 {
        font-size: 16px;
        text-align: center;
        color: black;
      }
        .other-product-container h3:hover {
          text-decoration: underline;
        }
    .other-product-img {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 125px;
      height: 125px;
    }
    .other-product-img-bg {
      display: flex;
      width: 125px;
      height: 125px;
      background-color: #FCFBED;
      border-radius: 0.75em;
    }
      .other-product-img-bg:hover {
        opacity: 0.8;
      }
}

@media (min-width: 1024px) {
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    margin-top: 32px;
  }
    .product .product-description {
      margin-top: 24px;
    }
    .product .content {
      width: 100%;
      max-width: 1366px;
      justify-content: space-around;
    }
      .product .content .shadow {
        padding: 24px;
        border-radius: 0.5em;
        background-color: white;
      }
        .product .content .shadow a {
          color: #F56600;
        }
        .product .content .shadow a:hover {
          color: #FEA100;
        }
      .col-1 {
        max-width: 600px;
      }
      .col-2 {
        max-width: 450px;
      }

  .product-detail-selection .quantity-input {
    width: 40px;
  }

  .product-detail-selection h3 {
    font-size: 24px;
  }
  .product-detail-selection h3.secondary-font {
    font-size: 18px;
  }
  .product-detail-selection .row.space-between {
    align-items: baseline;
  }

  .product-gallery {
    padding: 0px;
    border-radius: 0.75em;
  }
    .product-gallery .product-image {
      width: 450px;
      height: 450px;
    }
}