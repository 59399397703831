@media all {
  .success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
  }
    .success-container .content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
      .success-container .content .column {
        align-items: center;
      }
      .success-container .content svg {
        width: 90px;
        height: 90px;
      }
        .success-container .content .column .primary-button {
          width: 100%
        }

}

@media (min-width: 1024px) {
  .success-container .content {
    width: 500px;
    min-height: 65vh;
  }
    .success-container .content .column .primary-button {
      width: 304px
    }
}