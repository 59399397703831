
@media all {
  .faq {
    width: 100vw;
  }
    .faq a {
      color: #F56600;
      font-weight: bold;
    }
    .faq a:hover {
      color: #FEA100;
      font-weight: bold;
    }
  .faq .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 32px;
    background-color: white;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .question-container {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    max-height: 100px;
    transition: max-height 300ms;
  }
    .question-container {
      max-height: 1000px;
    }
    .question-container .question:hover {
      color: #F56600;
    }

  .faq .answer {
    overflow: hidden;
    height: auto;
    text-align: left;
    padding-left: 48px;
    margin: 0;
    max-height:0px;
  }
    .faq .answer.open {
      max-height: 1000px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
}

@media (min-width: 1024px) {
  .faq {
    align-items: center;
    width: 100%;
    max-width: 1366px;
  }
    .faq .content {
      margin-top: -32px;
      width: 100%;
      max-width: 1024px;
      align-items: flex-start;
      padding-left: 192px;
      padding-right: 192px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
}