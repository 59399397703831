:root {
  --primary-font: 'Julius Sans One', sans-serif;
  --secondary-font: 'Open Sans', sans-serif;
  --primary-orange: #F56600;
  --primary-brown: #6F1D00;
}

@media all {
  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  html {
    background-color: #FFFFFA;
  }

  body {
    display: flex;
    margin: 0px;
    overflow-x: hidden;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
    font-family: var(--secondary-font);
    color: #707070;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none;
  }

  ul {
    list-style-type: disc;
}

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  button, .button {
    font-family: var(--primary-font);
    background-color: var(--primary-orange);
    border: none;
    color: snow;
    height: 2.5em;
    cursor: pointer;
    border-radius: 0.25em;
    font-weight: bold;
  }
  button:focus {outline:0;}

  button:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: var(--primary-orange) !important;
  }



  .clickable {
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;
  }
  
  input, textarea {
    font-family: var(--secondary-font);
  }
  input::placeholder, textarea::placeholder {
    font-family: var(--secondary-font);
  }
input:focus, textarea:focus {
    outline: none;
  }


  h1, h2, h3 {
    font-family: var(--primary-font);
    font-weight: 100;
  }

  .border-box {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .primary-font {
    font-family: var(--primary-font);
  }

  .secondary-font {
    font-family: var(--secondary-font);
  }

  .primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    background-color: var(--primary-orange);
    color: #FFFDDE;
    border: 0px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
    .primary-button:hover {
      background-color: #FEA100;
    }
    .primary-button:active, .primary-button.active {
      background-color: #FEA100;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    }

  .secondary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    background-color: transparent;
    font-family: var(--primary-font);
    font-weight: bold;
    color: var(--primary-orange);
    border: 2px solid var(--primary-orange);
  }
    .secondary-button:hover {
      background-color: var(--primary-orange);
      border-color: var(--primary-orange);
      color: white
    }
    .secondary-button:active, .secondary-button.active {
      background-color: var(--primary-orange);
      border-color: var(--primary-orange);
      color: white
    } 

  .hover-link:hover {
    color: var(--primary-orange);
  }

  .text-link {
    color: var(--primary-orange);
    font-weight: bold;
  }

  .border-bottom {
    border-bottom: 1px solid #707070;
  }

  .text-align-center {
    text-align: center;
  }

  .primary-orange {
    color: var(--primary-orange);
  }

  .primary-brown {
    color: var(--primary-brown);
  }

  .shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }

  .content {
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 5px;
  }

  .hidden {
    visibility: hidden;
  }

  .thick-hr {
    border: 0.5px solid #6f1e0063
  }
  .thin-hr {
    opacity: 0.2;
  }
}

@media (min-width: 1024px) {
  html {
    display: flex;
    justify-content: center;
  }

  .content {
    width: 100%;
    max-width: 1366px;
  }
}