@media all {
  .contact-container .content {
    padding: 0px;
    flex-direction: column;
    margin-bottom: 32px;
  }
      .contact-container .content .shadow {
        margin-top: -32px;
        background-color: white;
        padding: 24px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 0.5em;
      }
  
  .contact-details-container {
    margin-top: 32px;
  }
    .contact-details-container .contact-details {
      padding: 12px;
      padding-top: 0px;
      width: 200px;
    }
    .contact-details-container .email-address-container {
      margin-bottom: 24px;
    }
      .contact-details-container .contact-details p {
        margin-top: 0px;
      }
      .contact-details-container .contact-details b {
        color: #6F1D00
      }
      .contact-details-container .contact-details h3 {
        margin-bottom: 12px;
      }
      .contact-details-container .contact-details a {
        color: #F56600;
        font-weight: bold;
      }
        .contact-details-container .contact-details a:hover {
          color: #FEA100
        }

  .contact-form {
    display: flex;
    flex-direction: column;
  }
    .contact-form input.button {
      padding: 8px;
    }
    .contact-form .optional-message {
      margin-top: 0px;
      font-size: 14px;
    }
      .contact-form .optional-message a {
        color: #F56600;
        font-weight: bold;
      }
      .contact-form .optional-message a:hover {
        color: #FEA100
      }
}

@media (min-width: 1024px) {
  .contact-container {
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1366px;
  }
    .contact-container .content {
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      max-width: 1224px;
    }
      .contact-container .content .shadow {
        margin-top: -64px;
      }
    .contact-container .contact-details-container {
      padding-left: 5%;
      margin-top: 24px;
    }
      .contact-details-container .contact-details {
        width: 350px;
      }
      .contact-details-container .contact-details h3 {
        font-size: 24px;
      }

  .contact-container .content .shadow {
    width: 515px;
  }
}
    
