@media all {
  .what-to-expect a {
    color: #F56600;
    font-weight: bold;
  }
    .what-to-expect p {
      text-align: justify;
    }
    .what-to-expect .banner .dashed-title h3 {
      font-size: 24px;
    }
    .what-to-expect a:hover {
      color: #FEA100;
      font-weight: bold;
    }
  .what-to-expect .content {
    margin-bottom: 32px;
    background-color: white;
    padding-bottom: 24px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 32px;
  }
    .what-to-expect .content .section {
      padding-bottom: 32px;
    }
    .what-to-expect .content ol li {
      font-weight: bold;
    }
  
  .what-to-expect .content .dashed-title {
    color: #6F1D00;
    padding-bottom: 8px;
  }
}

@media (min-width: 1024px) {
  .what-to-expect {
    align-items: center;
    width: 100%;
    max-width: 1366px;
  }
    .what-to-expect .content {
      margin-top: -32px;
      max-width: 1024px;
      padding-left: 220px;
      padding-right: 220px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
      .what-to-expect .content .dashed-title {
        padding-top: 42px;
      }
        .what-to-expect .banner .dashed-title h3 {
          font-size: 36px;
        }
        .what-to-expect .content .dashed-title h3 {
          font-size: 24px;
        }
}