@media all {
  .testimonials .content {
    flex-direction: column;
    align-items: center;
    padding: 24px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 32px;
    background-color: white;
  }
  
  .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    padding-bottom: 48px;
  }
    .testimonial h3 {
      color: #6F1D00
    }
    .testimonial span {
      font-size: 12px;
      margin-left: 4px;
    }
    .testimonial .thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      border-radius: 50px;
      margin-bottom: 16px;
      background-color: snow;
      border: 1px solid #6F1D00;
    }
}

@media (min-width: 1024px) {
  .testimonials {
    align-items: center;
    width: 100%;
    max-width: 1366px;
  }
    .testimonials .content {
      width: 100%;
      margin-top: -32px;
      max-width: 1024px;
      align-items: flex-start;
      padding-left: 128px;
      padding-right: 128px;
      padding-top: 36px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  
  .testimonial {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
    .testimonial .thumbnail {
      margin-right: 32px;
    }
    .testimonial .quote {
      width: 630px;
    }
}