
@media all {
  .description {
    display: flex;
    flex-direction: column;
    padding: 18px;
    margin-bottom: 16px;
    background-color: #6F1D00;
    color: white;
  }
    .description p {
      color: white;
      margin: 0;
    }
    .description .rare-earth-oils-logo {
      margin-top: 16px;
      width: 100%;
    }
    .description a {
      color: #F56600;
      font-weight: bold;
    }
    .description a:hover {
      color: #FEA100
    }

  .products-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: white;
    max-width: 1024px;
    margin-top: 8px;
  }

  .product-wrapper {
    width: 140px;
    cursor: pointer;
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
    .product-wrapper.placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0.8;
    }
      .product-wrapper.placeholder .product-image {
        background: beige;
        height: 150px;
        width: 150px;
      }
        .product-wrapper .product-image.image-hover {
          opacity: 0.8;
        }
      .product-wrapper.placeholder .placeholder-name {
        width: 130px;
        height: 20px;
        background: beige;
        margin-top: 18px;
      }
      .product-wrapper.placeholder .placeholder-price {
        width: 50px;
        height: 18px;
        background: beige;
        margin-top: 9px;
      }


  .product-image {
    position: relative;
    height: 150px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
    .product-image .primary-button {
      position: absolute;
      width: 130px;
      height: 35px;
      margin-left: -65px;
      background-color: #FEA100;
      color: #FFFDDE !important;
      left: 50%;
      bottom: 25px;
      z-index: 2;
      font-weight: bold;
    }
    .product-image .secondary-button {
      position: absolute;
      bottom: 70px;
      height: 35px;
      width: 130px;
      margin-left: -65px;
      left: 50%;
      z-index: 2;
      border-color: #FEA100;
      color: #FEA100;
      font-weight: bold;
    }

  .product-name {
    text-align: left;
    margin-bottom: 0px;
    color: black;
  }

  .product-price {
    text-align: left;
    margin-top: 6px;
    font-size: 18px;
    opacity: 0.5;
    font-family: 'Open Sans', sans-serif;
    color: #707070;
  }

  .quick-buy-modal {
    position: fixed;
    z-index: 100;
  }
    .quick-buy-modal .panel {
      position: absolute;
      z-index: 101;
      background-color: snow;
      top: 50%;
      margin-top: -255px;
      left: 50%;
      margin-left: -140px;
      width: 280px;
      height: 510px;
      padding: 18px;
      align-items: center;
      border-radius: 0.5em;
    }
    .quick-buy-modal .panel .product-image {
      width: 180px;
      height: 180px;
    }
}

@media (min-width: 1024px) {
  .shop {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1366px;
  }

  .description {
    flex-direction: row;
    padding: 32px;
    justify-content: space-between;
    max-width: 1024px;
  }
    .description p {
      width: 60%
    }
    .description .rare-earth-oils-logo {
      height: 66px;
      width: auto;
      margin-top: 0px;
    }

  .products-container {
    justify-content: flex-start;
  }


    .product-wrapper.placeholder .product-image {
      height: 224px;
      width: 224px;
    }


  .product-wrapper {
    width: 224px;
    margin: 16px;
  }
  .product-image {
    height: 224px;
  }
    .product-image .primary-button {
      height: 35px;
      width: 176px;
      margin-left: -88px;
    }
    .product-image .secondary-button {
      height: 35px;
      width: 176px;
      margin-left: -88px;
    }

    .quick-buy-modal .panel {
      margin-top: -269px;
      margin-left: -177px;
      width: 354px;
      height: fit-content;
      padding: 36px;
    }
}