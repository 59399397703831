@media all {
  .primary-button {
    font-size: 16px;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .header-row {
    position: relative;
    display: flex;
    padding-top: 8px;
    min-height: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .social-media-links {
    position: absolute;
    top: 12px;
    left: 12px;
  }
    .social-media-links > * {
      margin-right: 24px;
    }
    .social-media-links svg {
      color: #6F1D00;
    }
    .social-media-links svg:hover {
      color: #FEA100
    }

  .fixed-header {
    position: fixed;
    display: flex;
    z-index: 10;
    top: 12px;
    right: 12px
  }
    .fixed-header > * {
      display: flex;
      margin-left: 24px;
    }
    .fixed-header .primary-button {
      position: relative;
    }
      .fixed-header .primary-button .bag-count {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 16px;
        height: 1.5em;
        width: 1.5em;
        top: -0.75em;
        left: -0.75em;
        border-radius: 0.75em;
        background-color: #FEA100;
      }

  .menu-icon {
    display: flex;
    flex-direction: row;
  }
    .mob-header-icons > * {
      padding-right: 24px
    }
  
  .mob-dd-wrapper.open {
    background-color: #884527;
    padding-bottom: 0px;
  }
    .mob-dd-wrapper.open .mob-dd-header {
      padding-bottom: 24px;
    }

  .mob-dd-header {
    color: #FFFDDE;
    position: relative;
    margin: 0px;
  }
    .mob-dd-header .chevron {
      position: absolute;
      right: 12px;
      top: 4px;
    }
  .mob-dd-list {
    padding-left: 0;
    display: flex;
    flex-direction: column;
  }
    .mob-dd-list-item {
      padding-top: 14px;
      padding-bottom: 14px;
      color: #FFFDDE;
      font-size: 20px;
      border-top: 1px solid rgba(255, 253, 222, 0.24);
    }

  .logo {
    width: 250px;
  }


  .menu-list {
    align-items: center;
    background-color: #6F1D00;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
    .menu-list > * {
      text-align: center;
      width: 100%;
      border-top: 1px solid rgba(255, 253, 222, 0.24);
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 24px;
      color: #FFFDDE;
      text-decoration: none;
    }
    .menu-list .highlight {
      background-color: #884527;
    }
    .menu-list .mob-social-media-links {
      display: flex;
      justify-content: center;
    }
      .menu-list .mob-social-media-links > * {
        margin-left: 18px;
        margin-right: 18px;
        color: white;
      }
  }

@media (min-width: 1024px) {
  .header {
    padding-top: 24px;
  }
  .header-row {
    justify-content: center;
  }
  .header-link {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 20px;
    color: #5C5B4A;
  }

  .fixed-header {
    right: 24px;
    top: 48px;
  }
  .social-media-links {
    left: 24px;
    top: 24px;
  }

  .logo {
    width: 500px;
  }

  .dd-wrapper {
    cursor: pointer;
    position: relative;
  }
    .dd-wrapper .dd-header p {
      margin: 0;
      padding: 0;
    }
    .dd-wrapper .dd-list {
      position: absolute;
      z-index: 10;
      width: fit-content;
      padding: 0;
      margin: 0;
      list-style-type: none;
      background-color: white;
    }
      .dd-wrapper .dd-list .dd-list-item {
        display: flex;
        font-size: 16px;
        width: 150px;
        padding: 16px;
        border-top: 1px solid #F8F8F8;
        color: #5C5B4A
      }
        .dd-wrapper .dd-list .dd-list-item:hover {
          color: #FEA100;
        }
}