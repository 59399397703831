@media all {
  .services-wrapper .content {
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    padding-top: 32px;
    background-color: white;
  }

  .most-popular {
    border: 3px solid #6F1D00;
    position: relative;
    text-align: center;
  }
    .most-popular-text-container {
      position: absolute;
      background-color: white;
      top: -1em;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }
      .most-popular-text-container h3 {
        margin: 0px;
      }

  .services-panel {
    display: flex;
    flex-direction: column;
    background-position: center;
    align-items: center;
    margin: 18px;
    height: 400px; 
    border-radius: 3px;
  }
    .services-panel .text-container {
      display: flex;
      margin-top: -1px;
      flex-direction: column;
      width: 80%;
      background-color: white;
      align-items: center;
      padding: 18px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
      .services-panel .text-container hr {
        width: 75%;
      }
      .services-panel .text-container h3 {
        text-align: center;
      }
      .services-panel .text-container span {
        font-size: 14px;
      }

  .services-panel .services-panel-child {
    width: 100%;
  }
    .services-wrapper .content .cta-container {
      align-items: center;
      margin-top: 8px;
    }
      .services-wrapper .content .cta-container .primary-button {
        font-size: 20px;
      }
      .services-wrapper .content .cta-container p {
        text-align: left;
      }
  
  .services-wrapper .service-policies {
    width: 100%;
    padding: 12px;
    padding-top: 0px;
  }
}

@media (min-width: 1024px) {
  .services-wrapper {
    align-items: center;
    width: 100%;
    max-width: 1366px;
  }
    .services-wrapper .content {
      width: 100%;
      max-width: 1024px;
      margin-top: -32px;
      padding: 24px;
      padding-top: 96px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
      .services-wrapper .content .cta-container {
        align-items: center;
        margin-top: 22px;
      }
        .services-wrapper .content .cta-container p {
          text-align: center;
          width: 75%;
        }
  
  .services-panels {
    justify-content: space-around;
    width: 100%;
  }
  .services-panel {
    width: 300px;
  }

  .most-popular {
    margin-top: -24px;
    padding: 24px;
  }

  .services-wrapper .service-policies {
    width: 100%;
    max-width: 1024px;
  }
    .services-wrapper .service-policies h2 {
      font-size: 24px;
    }
    .services-wrapper .services-policies p {
      max-width: 420px;
    }
}