@media all {
  .shopping-bag {
    width: 100vw;
  }
  .shopping-bag .content {
    flex-direction: column;
    margin-bottom: 32px;
  }
    .shopping-bag .bag-item-image {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 150px;
      height: 150px;
    }

  .shopping-bag h2 {
    margin-bottom: 4px;
  }
  .shopping-bag hr {
    width: 100%;
  }
  .shopping-bag .panel {
    padding: 18px;
  }
  .shopping-bag .order-summary {
    border-radius: 0.5em;
    background-color: white;
  }
    .shopping-bag .order-summary button {
      width: 100%;
    }

  .bag-contents .container {
    display: flex;
    flex-direction: column;
  }
    .bag-contents .bagged-product .product-img {
      display: flex;
      width: 100%;
      justify-content: center;
      position: relative;
    }
      .bag-contents .bagged-product .product-img .x {
        position: absolute;
        right: 0;
      }
    .bag-contents .product-details {
      align-items: center;
      text-align: center;
    }
      .bag-contents .product-details .quantity {
        width: 100%;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
      }
      .bag-contents .product-details .product-cost {
        font-size: 12px;
        margin: 0;
      }
      .bag-contents .product-details h3 {
        color: black;
        margin-bottom: 0;
      }
}

@media (min-width: 1024px) {
  .shopping-bag {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    min-height: 62vh;
  }
  .shopping-bag .panel {
      min-width: 500px;
      width: 45%;
    }
    .shopping-bag .content {
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
    }
    .shopping-bag .order-summary {
      height: fit-content;
    }

  .bag-contents .container {
    flex-direction: row;
  }
    .bag-contents .bagged-product .product-img {
      width: auto;
    }
    .bag-contents .product-details {
      width: 100%;
      padding-left: 16px;
      align-items: flex-start;
      text-align: left;
    }
      .bag-contents .product-details .quantity {
        width: 100px;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
      }
    .bag-contents .product-details .product-name-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
    }
      .bag-contents .product-details .product-name-container .x {
        cursor: pointer;
      }
}