@media all {
  .payment-processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(111, 29, 0, 0.4);
    z-index: 100;
  }
    .payment-processing .payment-processing-content {
      background-color: white;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 0.75em;
      padding: 24px;
      padding-top: 48px;
      padding-bottom: 48px;
      margin: 16px;
    }
      .payment-processing .payment-processing-content h3 {
        color: #6f1d00;
        margin-bottom: 0;
        font-size: 24px;
      }
      


  .dashed-title {
    display: flex;
    width: 100%;
    flex-direction: row;
    white-space: nowrap;
  }
    .dashed-title h3 {
      font-weight: 500;
      margin: 0px;
      
    }
    .dashed-title .dash {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
    }
      .dashed-title .dash hr {
        width: 70%;
      }
  
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: #FFFFFA;
    padding: 8px;
    background-position: center;
    background-size: cover;
  }
    .banner h3 {
      font-size: 32px;
      font-weight: bold;
    }
    .banner hr {
      border: 1px solid white
    }
}

.modal-container {
  position: fixed;
  z-index: 11;
  top: 45px;
  right: 30px;
  width: 220px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  background-color: white;
}
  .modal-container .product-image-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
    .modal-container .product-image-container .product-image {
      width: 80px;
      height: 80px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  .modal-container .product-info-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
    .modal-container .product-info-container hr {
      width: 100%;
    }
    .modal-container .product-info-container h3 {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0px;
    }
    .modal-container .product-info-container button {
      width: 100%;
      font-weight: bold;
    }
    .modal-container .product-info-container .product-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
      .modal-container .product-info-container .product-title {
        display: flex;
        flex-direction: row;
      }
      .modal-container .product-info-container .product-info p {
        margin-top: 0px;
        font-size: 12px;
      }
    .modal-container .product-info-container button:hover {
      background-color: #FEA100;
    }

@media (min-width: 1024px) {
  .banner {
    height: 226px;
    width: 100%;
    max-width: 1400px;
    border-radius: 5px;
  }
    .banner .dashed-title {
      width: 80%;
      max-width: 1024px;
    }
      .dashed-title .dash hr {
        width: 90%;
      }
      .banner .dashed-title h3 {
        font-size: 36px;
      }
}