@media all {
  .about-reflexology ul {
    list-style: none;
  }
  .about-reflexology p {
    text-align: justify;
  }
    .about-reflexology .banner .dashed-title h3 {
      font-size: 24px;
    }
  .about-reflexology ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #6F1D00; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.2em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

  .about-reflexology a {
    color: #F56600
  }
    .about-reflexology a:hover {
      color: #FEA100
    }
  .about-reflexology .header {
    padding-top: 12px;
    padding-bottom: 24px;
  }

      .about-reflexology .content .what-is img, .about-reflexology .content .history img {
        width: 100%;
        border-radius: 5px;
        margin-top: 12px;
      }

  .about-reflexology .benefit-panels {
    display: flex;
    flex-direction: column;
  }
    .about-reflexology .benefit-panels h3 {
      text-align: center;
    }
    .about-reflexology .benefit-panels .panel {
      position: relative;
      margin-top: 70px;
      padding: 24px;
      padding-top: 70px;
    }
      .about-reflexology .benefit-panels .panel img {
        position: absolute;
        width: 140px;
        top: 0;
        margin-top: -70px;
        left: 50%;
        margin-left: -70px;
      }


  .about-reflexology .panel {
    background-color: white;
    padding: 24px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 48px;
    border-radius: 5px;
  }
    .about-reflexology .panel .dashed-title {
      color: #6F1D00;
    }
}

@media (min-width: 1024px) {
  .about-reflexology {
    align-items: center;
    margin-right: 24px;
    margin-left: 24px;
    width: 100%;
    max-width: 1366px;
  }
    .about-reflexology .banner .dashed-title h3 {
      font-size: 32px;
    }
    .about-reflexology .content .dashed-title h3 {
      font-size: 24px;
    }
    .about-reflexology .content {
      max-width: 1024px;
      margin-top: -32px;
    }
      .about-reflexology .panel {
        padding-bottom: 48px;
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 16px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
      }
      .about-reflexology .content .what-is {
        width: 100%;
        display: flex;
        padding-top: 32px;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
      }
        .about-reflexology .content .what-is > * {
          width: 45% !important;
        }
        .about-reflexology .content .what-is p {
          margin-top: 0;
        }
        .about-reflexology .content .what-is img {
          margin-top: 0;
        }
      
      .about-reflexology .content .history {
        width: 100%;
        display: flex;
        padding-top: 32px;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
        .about-reflexology .content .history p {
          width: 50%;
        }
        .about-reflexology .content .history .column {
          width: 30%;
          justify-content: space-between;
          height: 100%;
        }

      .about-reflexology .content .dashed-title {
        padding-top: 42px;
      }

  .about-reflexology .benefit-panels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
    .about-reflexology .benefit-panels .panel {
      width: 47%;
    }
}