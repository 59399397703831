@media all {
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFDDE;
    padding: 24px;
    width: 100%;
  }
    .footer-wrapper .wrapper {
      width: 100%;
    }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
    .footer-content h3 {
      font-weight: bold;
      font-size: 24px;
    }
    .footer-content a {
      color: #6F1D00;
    }
    .footer-content a:hover {
      color: #FEA100;
    }
}

@media (min-width: 1024px) {
  .footer-wrapper {
    margin-top: 32px;
    width: 100vw;
  }
  .footer-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 83%;
    margin: 0 auto;
  }
    .footer-wrapper .wrapper {
      max-width: 1366px;
    }
    .footer-wrapper h3 {
      margin-bottom: 13px
    }
}
