@media all {
  .section-header hr {
    opacity: 0.2;
  }

  .home-wrapper .primary-button, .home-wrapper .secondary-button {
    width: 100%;
  }

  .home-wrapper {
    align-items: center;
  }
    .home-wrapper .content {
      align-items: center;
    }
  
  .image-container {
    margin-top: 4px;
    margin-bottom: 4px;
    width: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
  }

  .landing-page {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .hero-image {
    position: relative;
    background-position-x: 38%;
    height: 83vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
    .hero-image h1 {
      text-align: center;
      color: #FCFBED;
      font-size: 24px;
      font-weight: 500;
    }

  .reflexology {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
    .reflexology .section-header .dashed-title h3 {
      font-size: 16px;
    }
  
  .section-header {
    width: 100%;
    padding-top: 64px;
    padding-bottom: 32px;
  }

  .panel-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
    .panel-container .overflow {
      margin-top: -48px;
    }
    .panel-container .text-container {
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */
      display: flex;
      flex-direction: column;
      padding: 22px;
      background-color: #FFFFFF;
      align-items: center;
      text-align: center;
      width: 85%;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
    .panel-container .text-container p {
      font-size: 14px;
    }
    .panel-container .text-container h3 {
      color: black;
      font-size: 16px;
      margin: 0;
    }

    .therapist {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
      .therapist .about-me {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        width: 100%;
        background-color: white;
      }
      .therapist .image-container {
        width: 100%;
        height: 95vw;
        border-radius: 0.5em;
        margin-bottom: 32px;
      }
        .therapist .about-me .quote {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 36px;
          border-radius: 0.5em;
        }
          .therapist .about-me img {
            margin-top: 32px;
            height: 100px;
            width: 100px;
          }

  .testimonial-samples {
    display: flex;
    width: 100%;
    align-items: center;
  }
    .testimonial-samples .secondary-button {
      margin-top: 32px;
    }
  
  .services {
    display: flex;
    width: 100%;
    align-items: center;
  }
  
  .contact-section {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 64px;
  }

  .carousel-container {
    align-items: center;
    width: 100%;
  }
    .carousel-container .chevron {
      cursor: pointer;
    }
    .carousel-container .panel {
      position: relative;
      padding: 16px;
      padding-top: 48px;
      padding-bottom: 32px;
      border-radius: 0.5em;
      margin-top: 38px;
      background-color: white;
    }
      .carousel-container .panel .thumbnail {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background-color: snow;
        border: 1px solid #6F1D00;
      }
        .carousel-container .panel h3 span {
          font-size: 12px;
          margin-left: 4px;
        }
    .carousel-container .selection {
      height: 1em;
      width: 1em;
      border: 1px solid #F56600;
      border-radius: 0.5em;
      margin: 1em;
      cursor: pointer;
    }
    .carousel-container .selection:hover {
      background-color: #F56600;
    }

    .contact-section .wrapper .shadow {
      padding: 16px;
      border-radius: 0.5em;
      background-color: white;
    }
}

@media (min-width: 1024px) {
  .home-wrapper {
    width: 100%;
    max-width: 1366px;
  }

  .section-header {
    padding-top: 128px;
    padding-bottom: 96px;
  }
    .section-header h3 {
      font-size: 30px;
    }
    .section-header hr {
      border: 0.5px solid #6F1D00;
      opacity: 0.1
    }
    
  .home-wrapper .primary-button, .home-wrapper .secondary-button {
    width: 400px;
    margin-top: 24px;
  }

  .hero-image {
    height: 568px;
    width: 100%;
  }
    .hero-image .text-container {
      width: 58%;
      font-size: 24px;
    }
      .hero-image .text-container h2 {
        font-size: 30px;
      }

  .landing-page {
    display: flex;
    flex-direction: row;
  }
    .landing-page .landing-page-links {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  
  .reflexology {
    width: 100%;
  }
    .reflexology .panels {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
      .reflexology .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 30%;
      }
      .reflexology .section-header .dashed-title h3 {
        font-size: 30px;
      }

    .therapist .about-me {
      flex-direction: row;
      justify-content: space-around;
    }
    .therapist .image-container {
      width: 422px;
      height: 422px;
      margin-bottom: 0;
    }
      .therapist .about-me .dashed-title h3 {
        font-size: 24px;
      }
      .therapist .about-me .quote {
        width: 600px;
        padding: 36px;
      }
        .therapist .about-me .quote img {
          margin-top: 16px;
        }
  
    .carousel-container .panel {
      display: flex;
      flex-direction: column;
      width: 1000px;
      padding: 24px;
      padding-top: 48px;
      height: 220px;
      justify-content: space-between;
    }
      .carousel-container .carousel {
        width: 100%;
        align-items: center;
        justify-content: space-around;
      }
      .carousel-container .selection-container {
        padding: 24px;
      }

      .services p {
        width: 66%;
        text-align: center;
        margin-top: 24px;
      }
      .services .services-panels {
        justify-content: space-around;
        width: 90%;
      }
      .services .services-panel {
        width: 350px;
      }
  
    .contact-section .wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
      .contact-section .section-header {
        padding-bottom: 16px;
      }
      .contact-section .instruction {
        padding-bottom: 32px;
      }
      .contact-section .primary-button {
        width: 100%;
      }
        .contact-section .contact-details-container {
          margin-left: 10%;
        }
        .contact-section .contact-details-container .contact-details h3 {
          font-size: 24px;
        }
        .contact-section .contact-details-container .contact-details {
          width: 300px;
        }

  .contact-section .wrapper .shadow {
    width: 50%;
    max-width: 700px;
    padding: 24px;
  }
}